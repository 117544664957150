import { FC } from 'react'

import { IconColor, IconProps, IconSize, SvgIcons } from './Icon.types'

const Icon: FC<IconProps> = ({
  size,
  height = IconSize.medium,
  width = IconSize.medium,
  type,
  color = IconColor.brand
}) => {
  const iconHeight = size || height
  const iconWidth = size || width
  const SvgIcon = type && SvgIcons[type]

  return (
    <>
      {
        SvgIcon && <SvgIcon width={iconWidth} height={iconHeight} color={color} />
      }
    </>
  )
}

export default Icon

export interface SelectElementType {
  id: string;
  image?: JSX.Element;
  title?: string;
  selected?: boolean;
  selectedTitle?: string;
  amount?: number;
  caption?: string;
  fullData?: unknown;
  popupInfo?: string;
}

export enum SelectIconType {
  checkbox = 'checkbox',
  radio = 'radio',
  checkmark = 'checkmark',
  button = 'button'
}

export enum ModalType {
  standard = 'standard',
  fullscreen = 'fullscreen',
  appMenu = 'app-menu',
  headerSearch = 'header-search',
  custom = 'custom',
  contextMenu = 'context-menu',
}

export enum ButtonType {
  primary = 'primary',
  primaryDark = 'primary-dark',
  primaryPale = 'primary-pale',
  warning = 'warning',
  normal = 'normal',
  white = 'white',
  dark = 'dark',
  grayC = 'gray-C',
  transparent = 'transparent',
  primaryWarning = 'primary-warning',
  primaryDarkRefreshed = 'primary-dark-refreshed',
  buttonColor = 'button-color',
  inActive = 'inactive'
}

export enum ButtonBlockType {
  regular =   'regular',
  small   =   'small'
}

export enum ButtonSize {
  xsmall = 'xsmall',
  small = 'small',
  normal = 'normal',
  big = 'big',
}

export enum AlertType {
  warning = 'ATTENTION',
  congrats = 'CONGRATS'
}

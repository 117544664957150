import { FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Modal from './Modal'
import ButtonsBlock from './ButtonsBlock'
import { ButtonBlockType, ButtonType } from '@app/types'
import i18n from '@app/libraries/i18n'

interface ConfirmationProps {
  open: boolean;
  confirmButtonType?: ButtonType;
  cancelButtonText?: string;
  cancelButtonType?: ButtonType;
  confirmButtonText?: string;
  confirmButtonId?: string;
  confirmDisabled?: boolean;
  headerText?: string;
  customAction?: boolean;
  customActionConfirm?: string;
  customActionCancel?: string;
  icon?: JSX.Element;
  children?: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  buttonBlockType?: ButtonBlockType
}

const Confirmation: FC<ConfirmationProps> = ({
  open,
  customAction,
  customActionConfirm,
  customActionCancel,
  confirmButtonType = ButtonType.buttonColor,
  cancelButtonText = customAction ?  i18n.t(`${customActionConfirm}`) : i18n.t('common.cancel'),
  cancelButtonType,
  confirmButtonText = customAction ?  i18n.t(`${customActionCancel}`) : i18n.t('common.confirm'),
  confirmButtonId,
  confirmDisabled,
  headerText,
  icon: IconElement,
  onClose,
  onConfirm,
  children,
  buttonBlockType
}) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose}>
      <StyledContent>
        <Header>
          {IconElement}
          <h1>{ t(headerText) }</h1>
        </Header>

        <StyledMessage>{children}</StyledMessage>
        <ButtonsBlock
          cancelButtonText={t(cancelButtonText)}
          confirmButtonText={t(confirmButtonText)}
          confirmButtonId={confirmButtonId}
          confirmType={confirmButtonType}
          confirmDisabled={confirmDisabled}
          cancelType={cancelButtonType}
          onConfirm={onConfirm}
          onClose={onClose}
          buttonBlockType={buttonBlockType}
        />
      </StyledContent>
    </Modal>
  )
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;

  h1 {
    font-size: var(--font-size-normal);
    text-align: center;
  }

  svg {
    margin-right: 0.25rem;
  }
`

const StyledMessage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: var(--font-size-normal);
  color: var(--font-color-primary);
  margin-bottom: 0.5rem;
`

export default Confirmation

import { CategoryEnum, OptionModel, PollCategory, TypeEnum, User } from '@app/graphql'

export interface PollEditableData {
  id: string;
  category: PollCategory;
  question: string;
  options: OptionModel[];
  expirationDate: string;
  isCommentsAllow: boolean;
}

export interface PostEditableData {
  id: string;
  title: string;
  category: CategoryEnum;
  type: TypeEnum;
  content: string;
  imageUrl: string;
  isCommentsAllow: boolean;
}
export interface PostTipEditableData {
  id: string;
  type: TypeEnum;
  imageUrl: string;
  isCommentsAllow: boolean;
  sportId: string;
  stake: number;
  analysis: string;
  vip: boolean;
  odds: number;
  bookieName: string;
}

export enum UpvoteStatus {
  upvote = 'UPVOTE',
  downvote = 'DOWNVOTE',
  none = 'NONE',
}

export interface DraggableListElement {
  id: string;
  text: string;
  position: number;
}

export type ContentUserData = Pick<User, 'displayName' | 'avatarUrl' | 'id'>

import { proxy } from 'valtio'

import { TipCreationStateType, TipCreationStep, TipsCreationListStateType } from '@app/types'

const TipCreationInitialState: TipCreationStateType = {
  tipTemporaryId: '',
  creationStep: TipCreationStep.event,
  sportId: '',
  sportName: '',
  countryId: '',
  countryName: '',
  competitionId: '',
  competitionName: '',
  eventId: '',
  eventName: '',
  eventData: null,
  marketId: '',
  marketName: '',
  searchEvent: '',
  eventDate: null,
  searchMode: false,
  outcomeId: '',
  outcomeName: '',
  odd: 0,
}

export const TipCreationState = proxy<TipCreationStateType>(TipCreationInitialState)

export const updateTipCreationState = (newState: Partial<TipCreationStateType> = TipCreationInitialState) => {
  Object.keys(TipCreationInitialState).forEach(key => {
    TipCreationState[key] = newState?.[key] || TipCreationInitialState[key]
  })
}


export const clearSportTipCreationState = () => {
  clearCountriesTipCreationState()
  TipCreationState.sportId = ''
  TipCreationState.sportName = ''
}

export const clearCountriesTipCreationState = () => {
  clearCompetitionsTipCreationState()
  TipCreationState.countryId = ''
  TipCreationState.countryName = ''
}

export const clearCompetitionsTipCreationState = () => {
  clearEventsTipCreationState()
  TipCreationState.competitionId = ''
  TipCreationState.competitionName = ''
}

export const clearEventsTipCreationState = () => {
  clearMarketsTipCreationState()
  TipCreationState.eventId = ''
  TipCreationState.eventName = ''
}

export const clearMarketsTipCreationState = () => {
  clearOutcomesTipCreationState()
  TipCreationState.marketId = ''
  TipCreationState.marketName = ''
}

export const clearOutcomesTipCreationState = () => {
  TipCreationState.outcomeId = ''
  TipCreationState.outcomeName = ''
}

const TipsCreationListInitialState: TipsCreationListStateType = {
  tipsList: [],
  isVIP: false,
  tipsComment: '',
  withComments: false,
  tipAnalysis: '',
  bookieName: '',
  bookieId: null,
  isCustomOdds: true,
  tipStake: 1,
  bookieList: [],
  includeInStat: true,
}

export const TipsCreationListState = proxy<TipsCreationListStateType>(TipsCreationListInitialState)

export const updateTipCreationListState = (
  newState: Partial<TipsCreationListStateType> = TipsCreationListInitialState
) => {
  Object.keys(TipsCreationListInitialState).forEach(key => {
    TipsCreationListState[key] = newState?.[key] || TipsCreationListInitialState[key]
  })
}



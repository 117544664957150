import { DraggableListElement } from '@app/types'

export const optionsInitialValues: DraggableListElement[] = [
  {
    id: '0',
    text: '',
    position: 0
  },
  {
    id: '1',
    text: '',
    position: 1
  }
]
import { FC, useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'

interface PortalProps {
  classNames?: string[];
  children?: React.ReactNode;
}

const Portal: FC<PortalProps> = ({ children, classNames }) => {
  const el = useMemo(() => document.createElement('div'), [])

  useEffect(() => {
    const documentBody = document.body
    if (classNames?.length) {
      classNames.forEach(className => el.classList.add(className))
    }
    documentBody.appendChild(el)
    return () => {
      documentBody.removeChild(el)
    }
  }, [ el, classNames ])

  return ReactDOM.createPortal(children, el)
}

export default Portal

import i18n from '@app/libraries/i18n'
import { FavouriteItemType } from '@app/types'

export const TITLES = {
  customOdds: i18n.t('tip.customOdds'),
  [FavouriteItemType.sport]: i18n.t('common.topSports'),
}


export const POST_TIP_ACTIONS = [
  { id: 1, title: 'common.won' },
  { id: 2, title: 'common.lost' },
  { id: 3, title: 'common.void' },
]
import { IconTypes } from './Icon.types'

// SPORT IDS AND MATCHING ICON TYPES
export const SportsIconsMap = new Map([
  [ '1', IconTypes.football ],
  [ '2', IconTypes.basketball ],
  [ '3', IconTypes.tennis ],
  [ '4', IconTypes.hockey ],
  [ '5', IconTypes.handball ],
  [ '6', IconTypes.volleyball ],
  [ '7', IconTypes.americanFootball ],
  [ '8', IconTypes.baseball ],
  [ '9', IconTypes.cricket ],
  [ '10', IconTypes.rugby ],
  [ '11', IconTypes.rugbyLeague ],
  [ '12', IconTypes.boxing ],
  [ '13', IconTypes.eSports ],
  [ '14', IconTypes.mma ],
  [ '15', IconTypes.tableTennis ],
])

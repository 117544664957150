import { getMessaging, getToken, Messaging, onMessage } from 'firebase/messaging'
import { getAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'
import { initializeApp } from 'firebase/app'

import env from '@app/environment'

export const firebaseConfig = process.env.NODE_ENV === 'development' ?
  null :
  {
    apiKey: env.FIREBASE_API_KEY,
    authDomain: env.FIREBASE_AUTH_DOMAIN,
    projectId: env.FIREBASE_PROJECT_ID,
    storageBucket: env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
    appId: env.FIREBASE_APP_ID,
    measurementId: env.FIREBASE_MEASUREMENT_ID,
  }

export const getFirebaseMessaging = (): Messaging => {
  if (!firebaseConfig) return
  try {
    const app = initializeApp(firebaseConfig)
    return getMessaging(app)
  } catch (e) {
    console.error('Error: ', e)
  }
}

export const loadToken = async (messaging: Messaging, registration: ServiceWorkerRegistration) => {
  try {
    if (!firebaseConfig) return
    return await getToken(messaging, {
      serviceWorkerRegistration: registration,
      vapidKey: env.FIREBASE_VAPID_KEY,
    })
  } catch (e) {
    console.error('Error: ', e)
    return null
  }
}

export const requestNotificationPermissions = async () => {
  try {
    await Notification?.requestPermission?.()
  } catch (e) {
    console.error('Error Notification Permission Request: ', e)
  }
}

let hasMessagesListener = false
export const handleForegroundMessages = async (messaging: Messaging) => {
  try {
    await requestNotificationPermissions()
    if (Notification?.permission === 'granted' && !hasMessagesListener) {
      onMessage(messaging, (payload) => {
        if (payload?.data) {
          const not = new Notification(payload.data.title, {
            body: payload.data.body,
            icon: payload.data.icon || './assets/branding/logotype-entry-x.png',
            ...payload
          })
          not.onclick = () => {
            not.close()
            window.location.href = `${self.location.origin}/inbox`
          }
        }
      })
      hasMessagesListener = true
    }
  } catch (e) {
    console.error(e)
  }
}

export const getFirebaseAnalytics = () => {
  if (!firebaseConfig) return
  try {
    const app = initializeApp(firebaseConfig)
    getAnalytics(app)

    // Initialize Performance Monitoring and get a reference to the service
    getPerformance(app)
  } catch (e) {
    console.error('Error: ', e)
  }
}
getFirebaseAnalytics()

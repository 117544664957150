import { ButtonSize, ButtonType, ModalType } from '@app/types'
import { FC } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import Modal from './Modal'
import { useTranslation } from 'react-i18next'

interface ContextMenuProps {
  open: boolean;
  children?: React.ReactNode;
  onClose: () => void;
  buttonType?: ButtonType;
  textColor?: string;
  border?: string;
}

const ContextMenu: FC<ContextMenuProps> = ({
  open,
  onClose,
  children,
  buttonType,
  textColor,
  border
}) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose} type={ModalType.contextMenu}>
      <MenuContent>
        <OptionsList>
          { children }
        </OptionsList>
        <Button
          className={'cancel-button'}
          type={buttonType ? buttonType : ButtonType.normal}
          size={ButtonSize.normal}
          onClick={onClose}
          textColor={textColor}
          border={border}
        >
          {t('common.cancel')}
        </Button>
      </MenuContent>
    </Modal>
  )
}

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;

  .cancel-button {
    font-weight: bold;
  }
`

const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;

  svg {
    margin-right: 0.5rem;
  }

  button {
    display: flex;
    align-items: center;
    padding: 0.75rem 0.5rem;
  }

`

export default ContextMenu
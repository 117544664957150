import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { Screen, ScreenHeader } from '@app/components'
import { Divider, IconTypes, ScrollContainer, SettingsBlockHeader, Switcher } from '@app/ui'
import {
  EntityNotificationSetting,
  GetNotificationSettingsDocument,
  NotificationSettingScope,
  UpdateNotificationSettingsDocument,
} from '@app/graphql'
import {
  NOTIFICATION_ACTIVITY_NAMES_MAP,
  NOTIFICATION_MESSAGES_NAMES_MAP,
  NOTIFICATION_SUBSCRIPTIONS_NAMES_MAP,
  NOTIFICATION_SYSTEM_NAMES_MAP,
  NOTIFICATION_TIPS_NAMES_MAP,
  ROUTES
} from '@app/constants'
import { NotificationItemType } from '@app/types'
import { CurrentUserState } from '@app/storage'

interface NotificationsSettingsDetailsScreenProps { }

const NotificationsSettingsDetailsScreen: FC<NotificationsSettingsDetailsScreenProps> = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const notification = location.state

  const [
    getNotificationSettings,
    { data: settingsData, loading: settingsLoading }
  ] = useLazyQuery(GetNotificationSettingsDocument, { fetchPolicy: 'network-only' })
  const [updateSettings] = useMutation(UpdateNotificationSettingsDocument)
  const { t } = useTranslation()
  const { id: currentUserId } = useSnapshot(CurrentUserState)

  useEffect(() => {
    getNotificationSettings({
      variables: {
        entityId: currentUserId,
        scope: NotificationSettingScope.User,
      },
    })
  }, [])

  const {
    systemNotifications,
    tipsNotifications,
    activityNotifications,
    messagesNotifications,
    subscriptionNotifications,
  } = useMemo(() => {
    const tipsNotifications: NotificationItemType[] = []
    const activityNotifications: NotificationItemType[] = []
    const subscriptionNotifications: NotificationItemType[] = []
    const messagesNotifications: NotificationItemType[] = []
    const systemNotifications: NotificationItemType[] = []

    const fillNotifications = (
      map: Map<string, string>,
      array: NotificationItemType[],
      not: Partial<EntityNotificationSetting>
    ) => {
      if (map.has(not?.notificationSetting?.name)) {
        array.push({
          id: not.id,
          label: map.get(not.notificationSetting.name),
          value: not.value,
          isEditable: not.isEditable,
        })
      }
    }

    if (settingsData?.entityNotificationSettingsByEntityId) {
      settingsData.entityNotificationSettingsByEntityId.forEach(not => {
        fillNotifications(NOTIFICATION_SYSTEM_NAMES_MAP, systemNotifications, not)
        fillNotifications(NOTIFICATION_TIPS_NAMES_MAP, tipsNotifications, not)
        fillNotifications(NOTIFICATION_ACTIVITY_NAMES_MAP, activityNotifications, not)
        fillNotifications(NOTIFICATION_SUBSCRIPTIONS_NAMES_MAP, subscriptionNotifications, not)
        fillNotifications(NOTIFICATION_MESSAGES_NAMES_MAP, messagesNotifications, not)
      })
    }

    return {
      systemNotifications,
      tipsNotifications,
      activityNotifications,
      subscriptionNotifications,
      messagesNotifications,
    }
  }, [settingsData])

  const onBack = useCallback(() => window.history.length > 2 ? navigate(-1) : navigate(ROUTES.feed.short), [])
  const onChange = (notificationId: string, value: boolean) => async () => {
    try {
      setLoading(true)
      await updateSettings({
        variables: {
          id: notificationId,
          scope: NotificationSettingScope.User,
          value,
        },
      })
      await getNotificationSettings({
        variables: {
          entityId: currentUserId,
          scope: NotificationSettingScope.User,
        },
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Screen
      title={t('common.notification_plural')}
      withNavigation
      noPadding
      loading={settingsLoading || loading}
      backgroundColor="var(--r-color-white)"
    >
      <StyledHeader
        title={t('common.notification_plural')}
        onIconClick={onBack}
        iconType={IconTypes.angleLeft}
        backgroundColor={'var(--r-color-white)'}
      />
      <>
        <StyledScroll>
          {/* <Divider size="custom" customSize={12} /> */}
          {/* <StyledSettingBlock title={t('common.system').toUpperCase()} /> */}
          {
            notification.toLowerCase() === 'system' && systemNotifications.map((n, index) => (
              <NotificationItem
                backgroundColor={index % 2 === 0 ? 'var(--r-color-lilac)' : 'var(--r-color-white)'}
                data={n}
                onChange={onChange(n.id, !n.value)}
                styledCheckBoxColor="var(--r-color-brand-mint)"
              />
            ))
          }
          {/* <StyledSettingBlock title={t('common.message_plural').toUpperCase()} /> */}
          {
            notification.toLowerCase() === 'messages' && messagesNotifications.map((n, index) => (
              <NotificationItem
                backgroundColor={index % 2 === 0 ? 'var(--r-color-lilac)' : 'var(--r-color-white)'}

                data={n}
                onChange={onChange(n.id, !n.value)}
                styledCheckBoxColor="var(--r-color-brand-mint)"
              />
            ))
          }
          {/* <StyledSettingBlock title={t('common.subscription_plural').toUpperCase()} /> */}
          {
            notification.toLowerCase() === 'subscriptions' && subscriptionNotifications.map((n, index) => (
              <NotificationItem
                backgroundColor={index % 2 === 0 ? 'var(--r-color-lilac)' : 'var(--r-color-white)'}
                data={n}
                onChange={onChange(n.id, !n.value)}
                styledCheckBoxColor="var(--r-color-brand-mint)"
              />
            ))
          }
          {/* <StyledSettingBlock title={t('common.activity').toUpperCase()} /> */}
          {
            notification.toLowerCase() === 'activity' && activityNotifications.map((n, index) => (
              <NotificationItem
                backgroundColor={index % 2 === 0 ? 'var(--r-color-lilac)' : 'var(--r-color-white)'}
                data={n}
                onChange={onChange(n.id, !n.value)}
                styledCheckBoxColor="var(--r-color-brand-mint)"
              />
            ))
          }
          {/* <StyledSettingBlock title={t('common.tip_plural').toUpperCase()} /> */}
          {
            notification.toLowerCase() === 'tips' && tipsNotifications.map((n, index) => (
              <NotificationItem
                backgroundColor={index % 2 === 0 ? 'var(--r-color-lilac)' : 'var(--r-color-white)'}
                data={n}
                onChange={onChange(n.id, !n.value)}
                styledCheckBoxColor="var(--r-color-brand-mint)"
              />
            ))
          }
        </StyledScroll>
      </>
    </Screen>
  )
}

interface NotificationItemProps {
  data: NotificationItemType;
  onChange: () => void;
  styledCheckBoxColor?: string;
  backgroundColor: string
}

const NotificationItem: FC<NotificationItemProps> = memo(({
  data,
  onChange,
  styledCheckBoxColor,
  backgroundColor
}) => {
  const { t } = useTranslation()

  const handleChange = () => data.isEditable ? onChange() : null

  return (
    <StyledItem className="vb-flex-row-spb-c" backgroundColor={backgroundColor}>
      <span className="vb-ellipsis-string">{t(data.label)}</span>
      <Switcher
        checked={data.isEditable ? data.value : false}
        disabled={!data.isEditable}
        onClick={handleChange}
        styledCheckBoxColor={styledCheckBoxColor}
        uncheckedColor={'var(--r-color-gray-C)'}
      />
    </StyledItem>
  )
})

const StyledHeader = styled(ScreenHeader)`
  padding-left: 20px;
  background-color: white;
`

const StyledScroll = styled(ScrollContainer)`
  flex: 1;
`

const StyledSettingBlock = styled(SettingsBlockHeader)`
  padding-left: 16px;
`

const StyledItem = styled.div<{ backgroundColor: string }>`
  width: 100%;
  height: 48px;
  padding: 0 8px 0 16px;
  border-bottom: 1px solid var(--r-color-gray-light);
  font-size: var(--font-size-normal);
  line-height: var(--font-size-extra-big);
  color: var(--font-color-black);
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : null};
`

export default NotificationsSettingsDetailsScreen

/**
 * Calculate date difference from current date in ms.
 * @param { Date } date date of event.
 * @returns { Number } date difference in ms.
 */
export const getTimeDifference = (date: Date): number => {
  const currentDate = new Date
  return date.getTime() - currentDate.getTime()
}

/**
 * Get countdown in specific unit.
 * @param { Number } timeDiff time difference in ms.
 * @returns { String } calculated time with corresponding unit.
 */
export const getCountdown = (timeDiff: number): string => {
  if (timeDiff < 0) return null

  const minutes = getMinutes(timeDiff)
  const hours = getHours(timeDiff)
  const days = getDays(timeDiff)

  if (days >= 1) {
    return `${days} days`
  } else if (hours >= 1) {
    return `${hours} hours`
  } else if (minutes >= 1) {
    return `${minutes} minutes`
  } else {
    return 'less than a minute'
  }
}

/**
 * Get passed date/time starting from current date.
 * @param { Date } date date of event.
 * @returns { String } passed date or time string.
 */
export const getRelativeDate = (date: Date): string => {
  const timeDiff = Math.abs(getTimeDifference(date))
  const minutes = getMinutes(timeDiff)
  const hours = getHours(timeDiff)

  if (minutes <= 1) {
    return 'Less than a minute ago'
  } else if (minutes === 1) {
    return `${minutes} minute ago`
  } else if (minutes < 60) {
    return `${minutes} minutes ago`
  } else if (hours === 1) {
    return `${hours} hour ago`
  } else if (hours < 24) {
    return `${hours} hours ago`
  } else {
    return getFullDateTime(date)
  }
}

/**
 * Transform milliseconds to minutes.
 * @param { Number } timeInMs time in milliseconds.
 * @returns { Number } number of minutes.
 */
export const getMinutes = (timeInMs: number): number => {
  return Math.floor(timeInMs / 1000 / 60)
}

/**
 * Transform milliseconds to hours.
 * @param { Number } timeInMs time in milliseconds.
 * @returns { Number } number of hours.
 */
export const getHours = (timeInMs: number): number => {
  return Math.floor(timeInMs / 1000 / 3600)
}

/**
 * Transform milliseconds to days.
 * @param { Number } timeInMs time in milliseconds.
 * @returns { Number } number of days.
 */
export const getDays = (timeInMs: number): number => {
  return Math.floor(timeInMs / 1000 / 3600 / 24)
}

/**
 * Get full date and time in string format.
 * @param { Date } date date of event.
 * @returns { String } date and time string.
 */
export const getFullDateTime = (date: Date): string => {
  const year: number = date.getFullYear()

  let day: string = date.getDate().toString()
  let month: string = (date.getMonth() + 1).toString()
  let hours: string = date.getHours().toString()
  let minutes: string = date.getMinutes().toString()

  if (day.length === 1) {
    day = `0${day}`
  }

  if (month.length === 1) {
    month = `0${month}`
  }

  if (hours.length === 1) {
    hours = `0${hours}`
  }

  if (minutes.length === 1) {
    minutes = `0${minutes}`
  }


  return `${day}.${month}.${year}  ${hours}:${minutes}`
}

/**
 * Compare current date with provided date to determine if it's passed
 * @param { Date } date date of event.
 * @returns { Boolean } true if provided date has passed.
 */
export const checkDateTime = (date: Date): boolean => {
  const currentDate = new Date
  return currentDate.getTime() - date.getTime() > 0 ? true : false
}

/**
 * Get expiration date.
 * @param { Number } time time in ms.
 * @returns { String } ISO date format string.
 */
export const getExpirationDate = (time: number): string => {
  const currentDate = new Date
  return (new Date(currentDate.getTime() + time)).toISOString()
}

export const convertDateToDotSeparated = (date): string => {
  const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit' }
  return new Intl.DateTimeFormat('de-DE', options).format(new Date(date))
}

export const convertDateToDotTimeSeparated = (date) => {
  const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false }
  return new Intl.DateTimeFormat('en-GB', options).format(new Date(date))
}
import { proxy } from 'valtio'

import { InboxStateType } from '@app/types'

const initialState: InboxStateType = {
  expandedTipId: '',
  featureName: '',
  featureDescription: '',
  conversationId: '',
  conversationTitle: '',
  conversationImageSrc: '',
}

export const InboxState = proxy<InboxStateType>(initialState)

export const resetInboxState = (resetData?: Partial<InboxStateType>) => {
  Object.keys(initialState).forEach(key => {
    InboxState[key] = resetData?.[key] || initialState[key]
  })
}

import env from '@app/environment'

const awsConfig = {
  'aws_project_region': env.AWS_PROJECT_REGION,
  'aws_cognito_identity_pool_id': env.AWS_COGNITO_IDENTITY_POOL_ID,
  'aws_cognito_region': env.AWS_COGNITO_REGION,
  'aws_user_pools_id': env.AWS_USER_POOLS_ID,
  'aws_user_pools_web_client_id': env.AWS_USER_POOLS_WEB_CLIENT_ID,
  'oauth': {
    'domain': env.AWS_OAUTH_DOMAIN,
    'scope': [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin'
    ],
    'redirectSignIn': env.AWS_OAUTH_REDIRECT_SIGN_IN,
    'redirectSignOut': env.AWS_OAUTH_REDIRECT_SIGN_OUT,
    'responseType': 'code'
  },
  'federationTarget': 'COGNITO_USER_POOLS',
  'aws_cognito_username_attributes': [],
  'aws_cognito_social_providers': [
    'FACEBOOK',
    'GOOGLE'
  ],
  'aws_cognito_signup_attributes': [
    'EMAIL'
  ],
  'aws_cognito_mfa_configuration': 'OFF',
  'aws_cognito_mfa_types': [
    'SMS'
  ],
  'aws_cognito_password_protection_settings': {
    'passwordPolicyMinLength': 8,
    'passwordPolicyCharacters': []
  },
  'aws_cognito_verification_mechanisms': [
    'EMAIL'
  ]
}


export default awsConfig

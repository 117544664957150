import { lazy } from 'react'

export const ButtonsBlock =  lazy(() => import('./ButtonsBlock'))
export const FullScreenModal =  lazy(() => import('./FullScreenModal'))
export const Calendar =  lazy(() => import('./Calendar'))
export const SelectModal =  lazy(() => import('./SelectModal'))
export const Popup =  lazy(() => import('./Popup'))
export const Confirmation =  lazy(() => import('./Confirmation'))
export const Modal =  lazy(() => import('./Modal'))
export const Alert =  lazy(() => import('./Alert'))
export const SignupPromptModal =  lazy(() => import('./SignupPromptModal'))
export const OnboardingAccountSwitcher =  lazy(() => import('./OnboardingAccountSwitch'))

import { FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button } from '@app/ui'
import { ButtonSize, ButtonType, ButtonBlockType } from '@app/types'
import i18n from '@app/libraries/i18n'

interface ButtonsBlockProps {
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonId?: string;
  confirmDisabled?: boolean;
  confirmType?: ButtonType;
  cancelType?: ButtonType;
  className?: string;
  onClose: () => void;
  onConfirm: () => void;
  buttonBlockType?: ButtonBlockType;
  disableConfirm?: boolean;
  disableCancel?: boolean;
}

const ButtonsBlock: FC<ButtonsBlockProps> = ({
  cancelButtonText = i18n.t('common.cancel'),
  cancelType = ButtonType.normal,
  confirmButtonText = i18n.t('common.confirm'),
  confirmButtonId,
  confirmDisabled,
  confirmType = ButtonType.buttonColor,
  className,
  onClose,
  onConfirm,
  buttonBlockType = ButtonBlockType.regular,
  disableConfirm,
  disableCancel
}) => {
  const { t } = useTranslation()

  const SmallButtons = () => {
    return (
      <StyledActionButtonContainer className="buttons-block vb-flex-row-spb-c">
        <Button
          size={ButtonSize.small}
          className="vb-font w900 small buttonColor"
          type={ButtonType.transparent}
          onClick={onClose}
          disabled={disableCancel}
        >
          {t(cancelButtonText)}
        </Button>
        <Button
          className="vb-font w900 small white py-10"
          size={ButtonSize.small}
          type={ButtonType.buttonColor}
          fluid20
          onClick={onConfirm}
          disabled={disableConfirm}
        >
          {t(confirmButtonText)}
        </Button>
      </StyledActionButtonContainer>
    )
  }
  const RegularButtons = () => {
    return (
      <StyledButtonsContainer className={className}>
        <StyledButtonWrapper>
          <Button
            size={ButtonSize.normal}
            type={cancelType}
            fluid
            onClick={onClose}
            className="cancel-button"
            borderRadius="5px"
            disabled={disableCancel}
          >
            {t(cancelButtonText)}
          </Button>
        </StyledButtonWrapper>
        <StyledButtonWrapper>
          <Button
            id={confirmButtonId}
            size={ButtonSize.normal}
            type={confirmType}
            fluid
            disabled={confirmDisabled}
            onClick={onConfirm}
            className="confirm-button"
            borderRadius="5px"
          >
            {t(confirmButtonText)}
          </Button>
        </StyledButtonWrapper>
      </StyledButtonsContainer>
    )
  }

  const renderButton = () => {
    if (buttonBlockType === ButtonBlockType.small) {
      return <SmallButtons />
    } else {
      return <RegularButtons />
    }
  }

  return renderButton()
}

const StyledButtonsContainer = styled.div`
  width: 100%;
  margin-top: .5rem;
  display: flex;
  justify-content: space-between;
`

const StyledButtonWrapper = styled.div`
  width: calc(50% - 0.5rem);

  button {
    font-weight: bold;
  }

  .confirm-button {
    color: var(--r-color-white);
    height: 50px;
    font-weight: bold;
  }
  .cancel-button {
    height: 50px;
    border: 2px solid;
    font-weight: 900; 
  }
  display: flex;
  // align-items: flex-end;
  justify-content: space-between;
`
const StyledActionButtonContainer = styled.div`
  .buttons-block {
    width: 100%;
    padding: 20px;
  }
  width: 100%;
`
export default ButtonsBlock

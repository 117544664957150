import { proxy } from 'valtio'

import { TipResult, TypesOfContent } from '@app/graphql'
import { PollEditableData, PostEditableData, PostTipEditableData } from '@app/types'

interface UpdatePostTipData {
  currentStatus: TipResult;
  newStatus: TipResult;
  tip: string
}
interface EditData {
  editData: PollEditableData | PostEditableData | PostTipEditableData | string;
  contentType: TypesOfContent | '';
}

const initialState: EditData = {
  editData: null,
  contentType: ''
}

const initialResultState: UpdatePostTipData = {
  currentStatus: null,
  newStatus: null,
  tip: ''
}

export const FeedEditState = proxy<EditData>(initialState)
export const PostTipresultUpdateState = proxy<UpdatePostTipData>(initialResultState)

export const resetFeedEditState = () => {
  Object.keys(initialState).forEach(key => {
    FeedEditState[key] = initialState[key]
  })
}

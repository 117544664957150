import { FC, useState, useEffect } from 'react'
import ProgressBar from '@ramonak/react-progress-bar'
import styled from 'styled-components'

interface LoaderProps {
  withOverlay?: boolean;
  className?: string;
  isRelative?: boolean;
}
const ProgreeBarLoader: FC<LoaderProps> = ({
  withOverlay,
  className,
  isRelative,
}) => {
  const [ completed, Setcompleted ] = useState(20)
  const maxCompleted = 100

  useEffect(
    () => {
      const timer = setTimeout(() => Setcompleted(completed > maxCompleted? 80: completed*2), 1000)
      return () => {
        clearTimeout(timer)
      }
    },
    [ completed ]
  )

  return (
    <LoaderContainer
      className={className}
      $withOverlay={withOverlay}
      $isRelative={isRelative}
    >
      <LoaderTitle>Hold on please</LoaderTitle>
      <LoaderImage />
      <ProgressBarContainer>
        <h4 className={'vb-font w700'}>
          fetching sports matches
          <span className={'dot-typing'} />
        </h4>
        <ProgressBar
          completed={completed}
          maxCompleted={maxCompleted}
          bgColor={'#212121'}
          customLabel={' '}
        />
      </ProgressBarContainer>
    </LoaderContainer>
  )
}

interface LoaderProps {
  withOverlay?: boolean;
  className?: string;
  isRelative?: boolean;
}

const SpinnerLoader: FC<LoaderProps> = ({
  withOverlay,
  className,
  isRelative,
}) => {
  return (
    <LoaderContainer
      className={className}
      $withOverlay={withOverlay}
      $isRelative={isRelative}
    >
      <LoaderSpinner />
    </LoaderContainer>
  )
}

export const LoaderContainer = styled.div<{ $withOverlay: boolean, $isRelative?: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
  display: flex;
  align-items: center;
  position: ${props => props.$isRelative ? 'relative' : 'absolute'};
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: ${(props) => (props.$withOverlay ? 1000 : undefined)};
  backdrop-filter: ${(props) => (props.$withOverlay ? 'blur(50px)' : undefined)};
  backdrop-filter: ${(props) => (props.$withOverlay ? 'blur(50px)' : undefined)};
  flex-direction: column;
`

export const LoaderSpinner = styled.div`
  width: 40px;
  height: 40px;
  animation: rotate 2s linear infinite;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/assets/icons/icon-spinner.png');

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const LoaderTitle = styled.h2 `
  padding-bottom: 5%;
`

export const LoaderImage = styled.div`
  width: 100%;
  height: 170px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/assets/icons/icon-384x384.png');
`

export const ProgressBarContainer = styled.div `
  width: 400px;
  padding-top: 6%;
  text-align: center;
  .dot-typing {
    position: relative;
    left: -9999px;
    width: 5px;
    max-width: 5px;
    height: 5px;
    border-radius: 5px;
    color: #212121;
    animation: dot-typing 1.5s infinite ease-in-out;
    display: inline-flex;
    margin-left: 20px;
  }
  h4 {
    padding: 5px;
    text-transform: capitalize;
  }
  
  @keyframes dot-typing {
    0% {
      box-shadow: 9984px 0 0 0 #212121, 9984px 0 0 0 #212121, 9984px 0 0 0 #212121;
    }
    16.667% {
      box-shadow: 9984px 0 0 0 #212121, 9999px 0 0 0 #212121;
    }
    33.333% {
      box-shadow: 9984px 0 0 0 #212121, 9999px 0 0 0 #212121, 10014px 0 0 0 #212121;
    }
    50% {
      box-shadow: 9984px 0 0 0 #212121, 9999px 0 0 0 #212121, 10014px 0 0 0 #212121;
    }
    66.667% {
      box-shadow: 9984px 0 0 0 #212121, 9999px 0 0 0 #212121;
    }
    83.333% {
      box-shadow: 9984px 0 0 0 #212121;
    }
    100% {
      box-shadow: 0 0 0 0 0;
    }
  }
  `

const Loader: FC<LoaderProps> = ({
  withOverlay,
  className,
  isRelative,
}) => {
  const [ shoulduseProgressBarLoader, _ ] = useState<boolean>(
    Boolean(parseInt(localStorage.getItem('useProgreeBarLoader') ?? '0'))
  )

  const props = {
    isRelative,
    className,
    withOverlay
  }

  return shoulduseProgressBarLoader? <ProgreeBarLoader {...props} />: <SpinnerLoader  {...props}/>
}

export default Loader

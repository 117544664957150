import { proxy } from 'valtio'
import env from '@app/environment'

import { UserAuthStateType } from '@app/types'

const LanguagesList = [
  { id: 'en', name: 'English', code: 'gb' },
  { id: 'es', name: 'Español', code: 'es' },
  { id: 'fr', name: 'Français', code: 'fr' },
  { id: 'sr', name: 'Serbian', code: 'rs' },
  { id: 'de', name: 'German', code: 'de' },
  { id: 'si', name: 'Slovenian', code: 'si' },
  { id: 'mk', name: 'Macedonian', code: 'mk' },
  { id: 'pt', name: 'Portuguese', code: 'pt' },
  { id: 'it', name: 'Italian', code: 'it' },
].filter(lang => env.SUPPORTED_LANGUAGES.includes(lang.id) )

const LanguagesListES = [
  { id: 'en', name: 'Inglesa', code: 'gb' },
  { id: 'es', name: 'Española', code: 'es' },
  { id: 'fr', name: 'francés', code: 'fr' },
  { id: 'sr', name: 'Serbia', code: 'rs' },
  { id: 'de', name: 'German', code: 'de' },
  { id: 'si', name: 'Slovenian', code: 'si' },
  { id: 'mk', name: 'Macedonian', code: 'mk' },
  { id: 'pt', name: 'Portuguesa', code: 'pt' },
  { id: 'it', name: 'Italiana', code: 'it' },
].filter(lang => env.SUPPORTED_LANGUAGES.includes(lang.id) )

const LanguagesListFR = [
  { id: 'en', name: 'Anglaise', code: 'gb' },
  { id: 'es', name: 'espagnol', code: 'es' },
  { id: 'fr', name: 'Français', code: 'fr' },
  { id: 'sr', name: 'serbe', code: 'rs' },
  { id: 'de', name: 'German', code: 'de' },
  { id: 'si', name: 'Slovenian', code: 'si' },
  { id: 'mk', name: 'Macédoine', code: 'mk' },
  { id: 'pt', name: 'Portugais', code: 'pt' },
  { id: 'it', name: 'Italien', code: 'it' },
].filter(lang => env.SUPPORTED_LANGUAGES.includes(lang.id) )

const LanguagesListSR = [
  { id: 'en', name: 'енглески језик', code: 'gb' },
  { id: 'es', name: 'Еспанол', code: 'es' },
  { id: 'fr', name: 'Францаис', code: 'fr' },
  { id: 'sr', name: 'Српски', code: 'rs' },
  { id: 'de', name: 'German', code: 'de' },
  { id: 'si', name: 'Slovenian', code: 'si' },
  { id: 'mk', name: 'Македонија', code: 'mk' },
  { id: 'pt', name: 'португалски', code: 'pt' },
  { id: 'it', name: 'Италијан', code: 'it' },
].filter(lang => env.SUPPORTED_LANGUAGES.includes(lang.id) )

const LanguagesMK = [
  { id: 'en', name: 'Англиски', code: 'gb' },
  { id: 'es', name: 'Еспањол', code: 'es' },
  { id: 'fr', name: 'француски', code: 'fr' },
  { id: 'sr', name: 'српски', code: 'rs' },
  { id: 'de', name: 'германски', code: 'de' },
  { id: 'si', name: 'словенечки', code: 'si' },
  { id: 'mk', name: 'Македонија', code: 'mk' },
  { id: 'pt', name: 'португалски', code: 'pt' },
  { id: 'it', name: 'италијански', code: 'it' },
].filter(lang => env.SUPPORTED_LANGUAGES.includes(lang.id) )

const LanguagesPT = [
  { id: 'en', name: 'Inglês', code: 'gb' },
  { id: 'es', name: 'Espanhola', code: 'es' },
  { id: 'fr', name: 'Francês', code: 'fr' },
  { id: 'sr', name: 'Sérvia', code: 'rs' },
  { id: 'de', name: 'Alemã', code: 'de' },
  { id: 'si', name: 'esloveno', code: 'si' },
  { id: 'mk', name: 'Macedônia', code: 'mk' },
  { id: 'pt', name: 'Português', code: 'pt' },
  { id: 'it', name: 'Italiana', code: 'it' },
].filter(lang => env.SUPPORTED_LANGUAGES.includes(lang.id) )

const LanguagesIT = [
  { id: 'en', name: 'Inglese', code: 'gb' },
  { id: 'es', name: 'Español', code: 'es' },
  { id: 'fr', name: 'Français', code: 'fr' },
  { id: 'sr', name: 'Serbo', code: 'rs' },
  { id: 'de', name: 'Tedesco', code: 'de' },
  { id: 'si', name: 'Sloveno', code: 'si' },
  { id: 'mk', name: 'Macedonian', code: 'mk' },
  { id: 'pt', name: 'Portoghese', code: 'pt' },
  { id: 'it', name: 'Italiano', code: 'it' },
].filter(lang => env.SUPPORTED_LANGUAGES.includes(lang.id) )

const translatedCountries = {
  en: LanguagesList,
  es: LanguagesListES,
  fr: LanguagesListFR,
  sr: LanguagesListSR,
  mk: LanguagesMK,
  pt: LanguagesPT,
  it: LanguagesIT
}

export const UserAuthState = proxy<UserAuthStateType>({
  userAppName: '',
  userCountryId: '',
  countriesList: [],
  userLanguageId: LanguagesList.length === 1? LanguagesList[0].id : '',
  languagesList: LanguagesList,
  userBirthdayDate: null,
  favouriteSports: new Set(),
  favouriteBookies: new Set(),
  favouriteMarkets: new Set(),
  favouriteCompetitions: new Map(),
  affiliateLinkName: '',
  inviteLinkName: '',
  inviteeTipsterLinkName: '',
  webpSupported: true,
  firstName: '',
  lastName: '',
  followLinkName: '',
  translatedCountry: translatedCountries,
  install: '',

})

import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import Screen from '@app/components/Screen/Screen'
import { InstallIOSPopup } from '@app/components'
import {
  Button,
  Divider,
  Icon,
  IconColor,
  IconSize,
  IconTypes,
  Modal,
} from '@app/ui'
import {
  BACKGROUND_ENTRY_PNG_IMAGE_SRC,
  BACKGROUND_ENTRY_WEBP_IMAGE_SRC,
  BrowserMapInitialKey,
  BrowsersMap,
  LOGOTYPE_IMAGE_SRC,
  LOGOTYPE_PNG_IMAGE_WITH_CAPTION_SRC,
  LOGOTYPE_WEBP_IMAGE_WITH_CAPTION_SRC,
  ROUTES,
  TIPSTER_ENTRY_IAM_PNG_IMAGE_SRC,
  USER_ENTRY_IAM_PNG_IMAGE_SRC,
} from '@app/constants'
import { ButtonSize, ButtonType } from '@app/types'
import { InstallAppContext } from '@app/context'
import { UserAuthState } from '@app/storage'
import { requestNotificationPermissions } from '@app/firebase'
import { UserRoles } from '@app/graphql'

interface EntryScreenProps { }

interface DomainTitleProps {
  weight?: number;
  size?: number;
  lineHeight?: number;
  paddingTop?: number;
}

export const DomainTitleComponent: React.FC<DomainTitleProps> = ({ weight, size, lineHeight, paddingTop }) => {
  const { t } = useTranslation()
  return (<DomainTitle
    weight={weight}
    size={size}
    paddingTop={paddingTop}
    lineHeight={lineHeight}>
    <Divider size="small" />
    {t('login.domaiN')}
  </DomainTitle>
  )
}

const EntryScreen: React.FC<EntryScreenProps> = () => {
  const { t } = useTranslation()
  const [currentBrowser, setCurrentBrowser] = useState(BrowserMapInitialKey)
  const [openInstallIOSModal, setOpenInstallIOSModal] = useState(false)
  const [openInstallModal, setOpenInstallModal] = useState(false)
  const { search: queryParams } = useLocation()
  const { event: installEvent } = useContext(InstallAppContext)
  const { webpSupported } = useSnapshot(UserAuthState)
  const navigate = useNavigate()

  const onCloseInstallIOSModal = () => setOpenInstallIOSModal(false)
  const onCloseInstallModal = () => setOpenInstallModal(false)
  const installApp = async () => {
    try {
      await installEvent.prompt()
      await installEvent.userChoice
    } finally {
      setOpenInstallModal(false)
    }
  }
  const onChangeBrowser = (id) => () => {
    if (id) setCurrentBrowser(id)
  }
  const onStart = (isTipster: boolean) => {
    localStorage.getItem('onboardingFlow') === UserRoles.Tipster || isTipster
      ? navigate(ROUTES.tipsterOnboarding.short + (queryParams || ''))
      : navigate(ROUTES.login.short + (queryParams || ''))
    requestNotificationPermissions()
  }

  const browserVideo = BrowsersMap.get(currentBrowser)

  return (
    <EntryScreenStyled
      title={t('common.welcome')}
    >
      <Divider size="logoTopSpace" />
      <picture>
        <source
          type="image/webp"
          srcSet={LOGOTYPE_IMAGE_SRC}
        />
        {/* <source type="image/png" srcSet={LOGOTYPE_PNG_IMAGE_WITH_CAPTION_SRC} /> */}
        <img
          className="logotype"
          src={LOGOTYPE_IMAGE_SRC}
          alt="Votebetting"
        />
      </picture>
      <DomainTitleComponent />
      <DomainSubtitle>Powering tipsters.</DomainSubtitle>
      <DomainSubtitle>Engaging users.</DomainSubtitle>
      <Divider />
      {/* <small className="spaced">{t('entry.joinCommunity')}</small> */}
      <Divider expanded />
      <div className="">
        <StyledStartButton
          size={ButtonSize.big}
          type={ButtonType.buttonColor}
          onClick={() => onStart(false)}
        >
          <p className="title">{t('entry.iamUser')}</p>
        </StyledStartButton>
        <StyledStartButton
          size={ButtonSize.big}
          type={ButtonType.buttonColor}
          onClick={() => onStart(true)}
        >
          <p className="title">{t('entry.iamTipster')}</p>
        </StyledStartButton>
      </div>
      <Divider size="huge" />

      {/* MODALS */}
      {openInstallModal && (
        <StyledModal open onClose={onCloseInstallModal}>
          <div className="install-content vb-flex-column-fs-c">
            <StyledModalHeader className="vb-flex-row-spb-c">
              <button
                className="left-arrow vb-flex-row-fs-c"
                onClick={onChangeBrowser(browserVideo.prevId)}
              >
                {browserVideo?.prevId ? (
                  <Icon
                    type={IconTypes.arrowLeft}
                    color={IconColor.brandDark}
                    size={IconSize.large}
                  />
                ) : null}
              </button>
              <p className="vb-font big w700 brandDarker">
                {browserVideo.name}
              </p>
              <button
                className="right-arrow vb-flex-row-fs-c "
                onClick={onChangeBrowser(browserVideo.nextId)}
              >
                {browserVideo?.nextId ? (
                  <Icon
                    type={IconTypes.arrowLeft}
                    color={IconColor.brandDark}
                    size={IconSize.large}
                  />
                ) : null}
              </button>
            </StyledModalHeader>
            <div className="install-demo">
              <video controls muted playsInline>
                <source src={browserVideo.videoUrl} type="video/mp4" />
              </video>
            </div>
            <div className="buttons-block vb-flex-row-spb-c">
              <Button
                size={ButtonSize.normal}
                className="vb-font w700"
                onClick={onCloseInstallModal}
              >
                {t('login.notNow')}
              </Button>
              <Button
                className="vb-font w700"
                size={ButtonSize.normal}
                type={ButtonType.primary}
                onClick={installApp}
              >
                {t('login.installApp')}
              </Button>
            </div>
          </div>
        </StyledModal>
      )}
      <InstallIOSPopup
        open={openInstallIOSModal}
        onClose={onCloseInstallIOSModal}
      />
    </EntryScreenStyled>
  )
}

const StyledStartButton = styled(Button)`
  margin: 0 10px;
  .title {
    padding: 0 20px;
  }
  color: white;
  width: 180px;

 @media only screen and (max-width: 768px){  
   margin-top: 5%;
  }
`

const DomainTitle = styled.p<DomainTitleProps>`
  font-weight:  ${(props) => (props.weight ? props.weight : 900)};
  font-style:   italic;
  font-size:    ${(props) => (props.size ? props.size : 30.41)}px;
  font-family:  Nunito-Bold;
  line-height:  ${(props) => (props.lineHeight ? props.lineHeight : 34.65)}px; 

`

const DomainSubtitle = styled.p`
  text-align: center;
  font-size:  16px;
  text-color: #3C3C3C;
`

const EntryScreenStyled = styled(Screen)`
  text-align: center;
  background-size: contain;
  height: 100vh;

  .logotype {
    width: 200px;
    display: block;
    margin: auto;
  }
`

const StyledModal = styled(Modal)`
  height: 100%;

  .modal-content {
    padding: 0 16px;
    height: 80%;
  }
  .install-content {
    height: 100%;
  }
  .install-demo {
    max-height: calc(100% - 120px);
    padding: 16px 0;
    flex: 1;

    video {
      height: 100%;
      object-fit: contain;
    }
  }
  .buttons-block {
    width: 100%;
  }
`

const StyledModalHeader = styled.div`
  height: 60px;
  width: 100%;

  .left-arrow,
  .right-arrow {
    min-width: 90px;
    background-color: transparent;
    border: none;
    padding: 0 24px;
  }
  .right-arrow {
    transform: rotateY(180deg);
    transform-origin: center center;
  }
`

export default EntryScreen

export const SEARCH_PARAMS = {
  channelId: 'channelId',
  durationId: 'durationId',
  openSubscribe: 'openSubscribe',
  chatId: 'chatId',
  // todo: temporary forbid to create new users
  existingUser: 'iamuser',
}

export const SEARCH_PARAM_VALUES = {
  [SEARCH_PARAMS.openSubscribe]: 'true',
  // todo: temporary forbid to create new users
  [SEARCH_PARAMS.existingUser]: '03152023sav',
}

import { proxy } from 'valtio'

interface SignupPromptModalType {
  title?: string;
  message?: string;
  showSignupModal: boolean;
}

const InitialSignupPromptModalState = {
  title: '',
  message: '',
  showSignupModal: false,
}

export const SignupPromptModalState = proxy<SignupPromptModalType>(InitialSignupPromptModalState)

export const  showSignupPromptModal = ({
  message,
  title,
}: Pick<SignupPromptModalType, 'message' | 'title'>): () => void => {
  return () => {
    SignupPromptModalState.showSignupModal = true
    SignupPromptModalState.message = message ?? ''
    SignupPromptModalState.title = title ?? ''
  }
}

export const closeSignupPromptModal = () => {
  Object.keys(InitialSignupPromptModalState).forEach(key => {
    SignupPromptModalState[key] = InitialSignupPromptModalState[key]
  })
}

import env from '@app/environment'

export const PAYMENTS_METHODS = [
  {
    id: 'Stripe',
    img: '/assets/images/payment/stripe.png',
    name: 'Stripe',
    height: 32,
  },
  {
    id: 'Manual',
    img: null,
    name: 'Manually',
    height: 32,
  },
]

// eslint-disable-next-line max-len
export const STRIPE_PUBLIC_KEY = env.STRIPE_PUBLIC_KEY

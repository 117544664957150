import { SportEventType, TournamentScope } from '@app/graphql'

/**
 * sort events for default list as "tournament scope", "tournament name"
 * implemented for issue "vot-416"
 * @param events
 */
export const sortDefaultEvents = (events: Partial<SportEventType>[]): Partial<SportEventType>[] => {
  if (!events?.length) return []

  const eventsMap = new Map([
    [ TournamentScope.Favorite, new Map<string, Partial<SportEventType>[]>() ],
    [ TournamentScope.Popular, new Map<string, Partial<SportEventType>[]>() ],
    [ TournamentScope.Regular, new Map<string, Partial<SportEventType>[]>() ],
  ])

  events.forEach(event => {
    if (!event?.tournament) return

    let eventMap = eventsMap.get(event.tournament.scope)
    if (!eventMap) eventMap = eventsMap.get(TournamentScope.Regular)

    if (eventMap.has(event.tournament.id)) {
      eventMap.get(event.tournament.id).push(event)
    } else {
      eventMap.set(event.tournament.id, [ event ])
    }
  })

  let sortedEvents: Partial<SportEventType>[] = []

  eventsMap.forEach(scopeEvents => {
    if (scopeEvents.size) {
      let scopeArr = []
      scopeEvents.forEach(idEvents => {
        if (idEvents.length) {
          scopeArr = scopeArr.concat(idEvents)
        }
      })
      scopeArr = scopeArr.sort((eventA, eventB) => {
        if (eventA?.tournament?.id === eventB?.tournament?.id) return 0
        if (eventA?.tournament?.category?.name && eventB?.tournament?.category?.name) {
          return eventA.tournament.category.name.localeCompare(eventB.tournament.category.name)
        }
        return 0
      })
      sortedEvents = sortedEvents.concat(scopeArr)
    }
  })

  return sortedEvents
}

/**
 * returns image path for flags by country code
 * @param code
 */
export const getFlagImageByCode = (name: string, ext='svg') => `/assets/flags/${name}.${ext}`

import React, { FC } from 'react'
import styled from 'styled-components'

interface AvatarProps {
  imageUrl: string;
  width: number;
  height: number;
  defaultImage?: string | undefined;
  className?: string;
  onClick?: () => void;
}

export const StyledAvatar: FC<AvatarProps> = ({
  imageUrl,
  height,
  width,
  className,
  onClick,
}) => {
  return (
    <Avatar
      className={className}
      src={imageUrl}
      width={width}
      height={height}
      onClick={onClick}
    />
  )
}

export const UnStyledAvatar: FC<AvatarProps> = ({
  imageUrl,
  height,
  width,
  className,
  onClick,
}) => {
  return (
    <AvatarUnstyled
      className={className}
      src={imageUrl}
      width={width}
      height={height}
      onClick={onClick}
    />
  )
}

const Avatar = styled.img`
  border-radius: 50%;
`
const AvatarUnstyled = styled.img`
  border-radius: 0%;
`

export default StyledAvatar

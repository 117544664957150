export enum ProfileType {
  channel = 'CHANNEL',
  user = 'USER',
  tipster = 'TIPSTER',
  admin = 'ADMIN',
  agent = 'AGENT',
}

export enum FavouriteItemType {
  sport = 'sport',
  // bookie = 'bookie',
  // competition = 'competition',
  // market = 'market',
}

import { TipDetailed, UserRoles } from '@app/graphql'
import { AllUserRoles } from '@app/types'

const homeAwayMarkets = [
  'Match Winner',
  'Home/Away',
  'Home/Away (1st Set)',
  'Home/Away (2nd Set)',
  'Home/Away (3rd Set)'
]

/**
 * function check if allowed to delete chosen content for current user
 * @param isPublic
 * @param userType
 * @param currentUserId
 * @param creatorId
 */
export const checkContentDeletion = ({ isPublic, userType, currentUserId, creatorId }: {
  isPublic: boolean,
  userType: AllUserRoles,
  currentUserId: string,
  creatorId: string,
}): boolean => (userType === UserRoles.Tipster && !isPublic && creatorId === currentUserId) ||
  (userType === UserRoles.Admin)

/**
 * function check if current user is allowed to edit content
 * @param userType
 * @param currentUserId
 * @param creatorId
 */
export const checkContentEditing = ({ userType, currentUserId, creatorId }: {
  userType: AllUserRoles,
  currentUserId: string,
  creatorId: string,
}): boolean => (userType === UserRoles.Tipster && creatorId === currentUserId) || (userType === UserRoles.Admin)

export const checkCommentDeletion = ({ isPublic, currentUserRole }: {
  isPublic: boolean,
  currentUserRole: AllUserRoles,
}): boolean => (currentUserRole === UserRoles.Admin) || (!isPublic && currentUserRole === UserRoles.Tipster)

export const parseOutcomeNameFromTipData = (tipData: TipDetailed) => {
  const market = tipData?.event?.oddType?.name || ''
  const outcome = tipData?.event?.outcome?.name || ''
  const competitors = tipData?.event?.competitors || []

  let outcomeName = outcome.trim()
  if (homeAwayMarkets.indexOf(market) >= 0 && outcome !== '' && competitors.length > 0) {
    for (const competitor of competitors) {
      if (outcome.trim().toLowerCase() === 'home' && competitor.type === 'LOCAL') {
        outcomeName = competitor.name + ' (' + outcome.trim() + ')'
      } else if (outcome.trim().toLowerCase() === 'away' && competitor.type === 'VISITOR') {
        outcomeName = competitor.name + ' (' + outcome.trim() + ')'
      }
    }
  }

  return `${outcomeName} ${tipData?.event?.outcome?.specialBetValue || ''}`.trim() || ''
}

export const createBookieIconSrc = (name: string) => name ?
  `https://dev-votebetting-s3-images.s3.eu-central-1.amazonaws.com/bookmakers/${name}.png` :
  ''

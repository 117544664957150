import { proxy } from 'valtio'
import { CurrentCountry } from '@app/types'

const initialState: CurrentCountry = {
  code: '',
  isBlacklisted: true,
  name: '',
  id: '',
  continent: null,
  language:''
}

export const CurrentCountryState = proxy<CurrentCountry>(initialState)

export const resetCurrentCountryState = (resetData?: Partial<CurrentCountry>) => {
  Object.keys(initialState).forEach(key => {
    CurrentCountryState[key] = resetData?.[key] || initialState[key]
  })
}

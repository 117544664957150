const chrome = {
  id: '1',
  name: 'Chrome',
  videoUrl: 'https://dev-votebetting-s3-images.s3.eu-central-1.amazonaws.com/demo.mp4',
}

const firefox = {
  id: '2',
  name: 'Firefox',
  videoUrl: 'https://dev-votebetting-s3-images.s3.eu-central-1.amazonaws.com/demo.mp4',
}

const safari = {
  id: '3',
  name: 'Safari',
  videoUrl: 'https://dev-votebetting-s3-images.s3.eu-central-1.amazonaws.com/demo.mp4',
}

export const BrowsersMap = new Map([
  [ chrome.id, { ...chrome, nextId: firefox.id, prevId: null } ],
  [ firefox.id, { ...firefox, nextId: safari.id, prevId: chrome.id } ],
  [ safari.id, { ...safari, nextId: null, prevId: firefox.id } ],
])

export const BrowserMapInitialKey = chrome.id

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
// import en from '@app/i18n/en.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    backend: {
      loadPath: '/i18n/{{lng}}.json',
    },
    // the translations
    // (tip move them in a JSON file and import them,
    // resources: { en: { translation: en } },
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: false,
    interpolation: {
      // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      escapeValue: false
    }
  })

export default i18n

import { Ads, Sports, ChannelContent, PinnedContent, TypesOfContent } from '@app/graphql'
import { SelectListHelper } from '@app/helpers'
import { proxy } from 'valtio'

interface PublicChannelData {
  isPublic: boolean;
  pinnedContent: PinnedContent;
  channelData: ChannelContent[];
  sports: Pick<Sports, 'id' | 'name'>[];
  offset: number;
  scrollPosition: number;
  hasMoreData: boolean;
  adsData: Partial<Ads>;
  adminJoinDisabled: boolean;
}

const initialState: PublicChannelData = {
  isPublic: true,
  pinnedContent: null,
  channelData: [],
  sports: [],
  offset: 0,
  scrollPosition: 0,
  hasMoreData: true,
  adsData: null,
  adminJoinDisabled: true
}

export const PublicChannelState = proxy<PublicChannelData>(initialState)

export const resetPublicChannelState = () => {
  Object.keys(initialState).forEach(key => {
    PublicChannelState[key] = initialState[key]
  })
}

export const resetPublicPinnedContent = () => {
  PublicChannelState.pinnedContent = null
}

export const updatePublicChannelEntity = (content: ChannelContent) => {
  const channelData = PublicChannelState.channelData as ChannelContent[]
  const index = channelData.findIndex(
    el => el.__typename === content.__typename
      && el.id === content.id
      && el.channelContentId === content.channelContentId
  )

  channelData[ index ] = content
  PublicChannelState.channelData = channelData
}

export const getPublicChannelContentEntity = (id: string, contentType: TypesOfContent): ChannelContent => {
  const channelData = PublicChannelState.channelData
  return channelData.find(
    el => SelectListHelper.contentTypeMap.get(el.__typename) === contentType && el.id === id
  )
}

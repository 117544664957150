import { ChangeEvent, FC, useState } from 'react'
import styled, { css } from 'styled-components'
import { Icon, IconTypes, IconColor } from './Icon'
import { Popup } from './Modal'
import { useTranslation } from 'react-i18next'
import { fileExtensions } from '@app/helpers/misc'

interface UploadInputProps {
  onUpload?: (file: File) => void;
}

const UploadInput: FC<UploadInputProps> = ({
  onUpload,
}) => {
  const { t } = useTranslation()

  const [ openTooBigImagePopup, setOpenTooBigImagePopup ] = useState(false)
  const [ errorMsg, setErrorMsg ] = useState('')

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) =>{
    const fileExt = e.target.files[0].type.split('/')[1].toLocaleUpperCase()
    if(!fileExtensions.includes(fileExt)){
      setErrorMsg(t('invalid.file.format'))
      setOpenTooBigImagePopup(true)
      return
    }

    if (e.target.files[0]?.size > 1040000) {
      setErrorMsg(t('invalid.file.size'))
      setOpenTooBigImagePopup(true)
      return
    }
    onUpload(e?.target?.validity?.valid && e?.target?.files?.[0] || null)
  }
  return <>
    <input hidden
      required={true}
      accept="image/*"
      type="file"
      onChange={handleFileUpload}/>
    <Popup
      open={openTooBigImagePopup}
      headerText={t('common.attention').toUpperCase()}
      Icon={<Icon type={IconTypes.alertTriangle} color={IconColor.warning} />}
      closeButtonText={t('common.ok').toUpperCase()}
      onClose={() => setOpenTooBigImagePopup(false)}
    >
      {errorMsg}
    </Popup>
  </>
}

interface RichUploadInputProps {
  onUpload?: (file: File, name: string) => void;
  label?: string;
  preview?: boolean;
  title?: string;
  name?: string;
  value?: string;
}

export const RichUploadInput: FC<RichUploadInputProps> = ({
  onUpload,
  title,
  preview=true,
  label='Upload/Change',
  name,
  value
}) => {
  const [ file, setFile ] = useState<string>(value)
  const handleChange = (file: File) => {
    setFile(URL.createObjectURL(file))
    onUpload(file, name)
  }
  return (
    <StyledUploadInputContainer>
      <StyledUploadInputColumn style={{ textAlign: 'left', lineHeight: '35px' }}>
        {title}
      </StyledUploadInputColumn>
      <StyledUploadInputColumn>
        {
          preview? (<div>
            <StyledUploadInputImg src={file} />
          </div>): null
        }
      </StyledUploadInputColumn>
      <StyledUploadInputColumn style={{ textAlign: 'right' }}>
        {
          file? (<UploadInputLabel required={false} onClick={() => setFile('')}>
            <Icon type={IconTypes.close} color={IconColor.fontBlack} />
            <span style={{ position: 'relative', bottom: '7px' }}>Clear</span>
          </UploadInputLabel>): null
        }
        <UploadInputLabel required={false}>
          <UploadInput onUpload={handleChange} />
          <Icon type={IconTypes.shareIOSSafariModern} color={IconColor.fontBlack} />
          <span style={{ position: 'relative', bottom: '5px' }}>{label}</span>
        </UploadInputLabel>
      </StyledUploadInputColumn>
    </StyledUploadInputContainer>
  )
}

const StyledUploadInputColumn = styled.div`
  flex: 33.33%;
  min-height: 25px;
  margin: 5px 0;
`

const StyledUploadInputImg = styled.img`
  width: auto;
  max-height: 35px;
  margin: auto;
`

const StyledUploadInputContainer = styled.div`
  display: flex;
`

const UploadInputLabel = styled.label<{required: boolean}>`
padding: 0 5px;
margin-bottom: 10px;
color: var(--color-gray-darker);
font-size: var(--font-size-extra-small);
${(props) => props.required && css`&:after {content: " *"; color: var(--color-gray-darker)}`}
`

export default UploadInput

import { proxy } from 'valtio'

import { MatchListStateType } from '@app/types'

const initialState: MatchListStateType = {
  sportId: '',
  categoryId: '',
  competitionId: '',
  competitionName: '',
  eventsDate: new Date(),
  eventId: '',
  favouriteEvents: new Set(),
}

export const MatchListState = proxy<MatchListStateType>(initialState)

export const resetMatchListState = (resetData?: Partial<MatchListStateType>) => {
  Object.keys(initialState).forEach(key => {
    MatchListState[key] = resetData?.[key] || initialState[key]
  })
}

interface EventState {
  eventLists: any
}
const initialEventState: EventState = {
  eventLists: []
}

export const EventList = proxy<EventState>(initialEventState)

export const resetEventListState = (resetData?: Partial<EventState>) => {
  Object.keys(initialState).forEach(key => {
    EventList[key] = resetData?.[key] || initialEventState[key]
  })
}
import { proxy } from 'valtio'

interface ChannelInviteType {
  uid: string;
}
export const ChannelInviteState = proxy<ChannelInviteType>({
  uid: ''
})

export const resetChannelInviteState = () => {
  ChannelInviteState.uid = ''
}

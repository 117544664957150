import { proxy } from 'valtio'

interface AppUpdateStateType {
  updateDetected: boolean;
  registration: ServiceWorkerRegistration;
}
export const AppUpdateState = proxy<AppUpdateStateType>({
  updateDetected: false,
  registration: null
})

export const resetAppUpdateState = () => {
  AppUpdateState.updateDetected = false
  AppUpdateState.registration = null
}

import { Ads, Sports, ChannelContent, ChannelDetailed, PinnedContent, TypesOfContent } from '@app/graphql'
import { SelectListHelper } from '@app/helpers'
import { proxy } from 'valtio'

interface PrivateChannelData {
  id: string;
  logo: string
  title: string;
  pinnedContent: PinnedContent;
  channelData: ChannelContent[];
  offset: number;
  channelsList: ChannelDetailed[];
  scrollPosition: number;
  hasMoreData: boolean;
  adsData: Partial<Ads>;
  newChannelId: string;
  sports: Pick<Sports, 'id' | 'name'>[];
}

const initialState: PrivateChannelData = {
  id: '',
  logo: '',
  title: '',
  pinnedContent: null,
  channelData: [],
  sports: [],
  offset: 0,
  channelsList: [],
  scrollPosition: 0,
  hasMoreData: true,
  newChannelId: null,
  adsData: null
}

export const PrivateChannelState = proxy<PrivateChannelData>(initialState)

export const resetPrivateChannelState = () => {
  Object.keys(initialState).forEach(key => {
    PrivateChannelState[key] = initialState[key]
  })
}

export const resetPrivatePinnedContent = () => {
  PrivateChannelState.pinnedContent = null
}

export const updatePrivateChannelEntity = (content: ChannelContent) => {
  const channelData = PrivateChannelState.channelData
  const index = channelData.findIndex(
    el => el.__typename === content.__typename
      && el.id === content.id
      && el.channelContentId === content.channelContentId
  )

  channelData[ index ] = content
  PrivateChannelState.channelData = channelData
}

export const getPrivateChannelContentEntity = (id: string, contentType: TypesOfContent): ChannelContent => {
  const channelData = PrivateChannelState.channelData
  return channelData.find(
    el => SelectListHelper.contentTypeMap.get(el.__typename) === contentType && el.id === id
  )
}
export const checkIsSafari = () => {
  const userAgent = window?.navigator?.userAgent
  if (!userAgent) return false

  const webkit = !!userAgent.match(/WebKit/i)
  const isIPad = !!userAgent.match(/iPad/i)
  const isIPhone = !!userAgent.match(/iPhone/i)
  const isMac = !!userAgent.match(/Macintosh/i)
  const isIOS = isIPad || isIPhone
  // const isSafari = (isIOS || isMac) && webkit && !userAgent.match(/CriOS|FxiOS/i)
  // return isSafari
  return isIOS && webkit && !userAgent.match(/CriOS/i)

}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const checkIsSafariStandalone = () => navigator?.standalone

export const checkIsModernIOS = () => {
  if (window?.navigator?.userAgent) {
    return /OS (13|14|15)/.test(window.navigator.userAgent)
  }
  return false
}

export const checkWebpSupport = (callback: (result: boolean) => void) => {
  const testImage = 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA'
  const img = new Image()
  img.onload = function () {
    const result = (img.width > 0) && (img.height > 0)
    callback(result)
  }
  img.onerror = function () {
    callback(false)
  }
  img.src = 'data:image/webp;base64,' + testImage
}

export const isInstalledApp = () => window.matchMedia('(display-mode: standalone)').matches? 'app': 'web'

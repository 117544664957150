import i18n from '@app/libraries/i18n'

export const NOTIFICATION_SYSTEM_NAMES_MAP = new Map([
  [ 'userWarning', i18n.t('notification.userWarning') ],
  [ 'referralJoined', i18n.t('notification.referralJoined') ],
  [ 'appUpdated', i18n.t('notification.appUpdated') ],
])

export const NOTIFICATION_TIPS_NAMES_MAP = new Map([
  [ 'tipsterAddedVipTip', i18n.t('notification.tipsterAddedVipTip') ],
  [ 'tipsterAddedFreeTip', i18n.t('notification.tipsterAddedFreeTip') ],
  [ 'tipResult', i18n.t('notification.tipResult') ],
])

export const NOTIFICATION_ACTIVITY_NAMES_MAP = new Map([
  [ 'pollResult', i18n.t('notification.pollResult') ],
  [ 'pinnedContent', i18n.t('notification.pinnedContent') ],
  [ 'newPost', i18n.t('notification.newPost') ],
  [ 'eventVoteResult', i18n.t('notification.eventVoteResult') ],
])

export const NOTIFICATION_SUBSCRIPTIONS_NAMES_MAP = new Map([
  [ 'subscriptionExpired', i18n.t('notification.subscriptionExpired') ],
])

export const NOTIFICATION_MESSAGES_NAMES_MAP = new Map([
  [ 'messages', i18n.t('notification.messages') ],
])

import { proxy } from 'valtio'

interface AppMenuStateType {
  opened: boolean;
}
export const AppMenuState = proxy<AppMenuStateType>({
  opened: false
})

export const closeAppMenu = () => {
  AppMenuState.opened = false
}

import { proxy } from 'valtio'

import { AppSettingsStateType } from '@app/types'

export const AppSettingsState = proxy<AppSettingsStateType>({
  favouriteSports: new Set(),
  bookiesList: [],
  favouriteBookies: new Set(),
  leaguesList: [],
  favouriteCompetitions: new Set(),
  marketsList: [],
  favouriteMarkets: new Set(),
})

import { proxy } from 'valtio'

interface NotificationCounterType {
  notificationCounter: number;
  messagesCounter: number;
  clicked: string;
}
const initialState: NotificationCounterType = {
  notificationCounter: 0,
  messagesCounter: 0,
  clicked: ''
}

export const NotificationCounterState = proxy<NotificationCounterType>(initialState)

export const resetNotificationCounterState = () => {
  Object.keys(initialState).forEach(key => {
    NotificationCounterState[key] = initialState[key]
  })
}
export const extractYoutubeLinks = (text: string): string[] => {
  const pattern =
      /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/g
  const matches: RegExpMatchArray | null = text.match(pattern)
  const videoIds: string[] = matches
    ? matches.map((match) => match.split('=')[1])
    : []
  const youtubeLinks: string[] = videoIds.map(
    (videoId) => `https://www.youtube.com/watch?v=${videoId}`
  )
  return youtubeLinks
}

export const autoLinkYouTube =(text)=> {
  const youtubeRegex = /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/g
  return text.replace(youtubeRegex, function(match, p1, p2, p3, p4) {
    const youtubeLink = (p1 ? p1 : 'https://') + (p2 ? p2 : '') + (p3 ? p3 : '') + p4
    return '<a href="' + youtubeLink + '" target="_blank">' + youtubeLink + '</a>'
  })
}
